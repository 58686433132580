import React from 'react';
import PropTypes from "prop-types";

import { Section } from 'components/Section';

import styles from './style.module.scss';

import { Separator } from 'components/Separator';

class Markdown extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div className={styles.container} >
                {this.props.contentsTable && 
                    <>
                        <Section className={styles.contentsTable} margin="none">
                            <h2>Contents</h2>
                            <div className={styles.contentsTable} dangerouslySetInnerHTML={{ __html: this.props.contentsTable }}/>
                        </Section>
                        <Separator />
                    </>
                }
                <Section margin="none">
                    <div className={styles.body} dangerouslySetInnerHTML={{ __html: this.props.html }}/>
                </Section>
            </div>
        );
    }
}

Markdown.propTypes = {
    html: PropTypes.string.isRequired
};

export default Markdown;