import React from 'react';

import style from './style.module.scss';

export function Section({children, margin, id, className, ...rest}){
    var classStr = style.container;

    if (margin){
        switch (margin) {
            case "none":
                classStr += " "+style.margin_none;
                break;

            case "top":
                classStr += " "+style.margin_top;
                break;

            case "bottom":
                classStr += " "+style.margin_bottom;
                break;
        
            default:
                throw new Error('Unknown section margin type');
        }
    }

    if (className){
        classStr += " "+className;
    }

    return (
        <section id={id} className={classStr} {...rest}>
            {children}
        </section>
    );
}