import React from 'react';

import './style.scss';

import CornerOnLight from 'media/RightAngleOnLight.inline.svg';
import CornerOnDark from 'media/RightAngleOnDark.inline.svg';

export function BorderedBox({children, onLight, className}){
    let Image;
    if (onLight){
        Image = CornerOnLight;
    }else{
        Image = CornerOnDark;
    }

    let classStr = "BBCorner ";
    if (className){
        classStr += className + " ";
    }

    const newChildren = React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
            style: {position: "relative"}
        }, 
            <Image className={classStr + "LT"} alt=""/>,
            <Image className={classStr + "LB"} alt=""/>,
            <Image className={classStr + "RT"} alt=""/>,
            <Image className={classStr + "RB"} alt=""/>,
            child.props.children
        );
    });

    return (
        <>
            {newChildren}
        </>
    );
}

// Set default props
BorderedBox.defaultProps = {
    onLight: true
};