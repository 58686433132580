import React from 'react';
import { graphql } from "gatsby";
import Img from "gatsby-image";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';

import style from './style.module.scss';

import { BorderedBox } from 'components/BorderedBox';
import Markdown from 'components/Markdown';
import RevTime from 'components/RevTime';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';
import IndyLink from 'components/IndyLink';
import Emoji from 'components/Emoji';

export default function BlogPostPage({data}){
    const post = data.post;
    const author = post.frontmatter.author;

    let toc;
    if (post.frontmatter.showContentsTable === true){
        toc = post.tableOfContents;
    }else{
        toc = null;
    }

    // Get created and last updated TS
    let createdTS = null;
    let latestTS = null;
    for (let i = 0; i < post.frontmatter.revisions.length; i++) {
        const TS = post.frontmatter.revisions[i].time;
        if (createdTS === null || createdTS > TS){
            createdTS = TS;
        }
        if (latestTS === null || latestTS < TS){
            latestTS = TS;
        }
    }

    if (latestTS === createdTS){
        latestTS = 0;
    }

    const pagePath = "/blog/"+post.fields.slug;

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO 
                headType = "BlogPost"
                title = {post.frontmatter.title}
                description = {post.frontmatter.previewText}
                author = {author}
                previewImg = {{
                    url: post.frontmatter.featuredImg.childImageSharp.fixed.src,
                    width: 800,
                    height: 400,
                    alt: post.frontmatter.featuredImgAlt
                }}
                createdTS = {createdTS}
                lastUpdatedTS = {latestTS}
                path = {pagePath}
                wordCount = {post.wordCount.words}
            />
            <div id={style.container}>
                <Section id={style.titleCont} style={{backgroundImage: "url(" + post.frontmatter.featuredImg.publicURL + ")"}}> 
                    <BorderedBox className={style.BBCorner}>
                        <div id={style.title}>
                            <h1>{post.frontmatter.title}</h1>
                            <p>{post.frontmatter.previewText}</p>
                        </div>
                    </BorderedBox>
                    {post.frontmatter.featuredImgCredit && 
                        <div id={style.coverImgDetails}>
                            <p>Photo credit: {post.frontmatter.featuredImgCredit}</p>
                        </div>
                    }
                </Section>
                <div id={style.detailsCont}>
                    <div id={style.authorDetailsCont}>
                        <Img className={style.authorImg} 
                            fixed={author.profilePic.childImageSharp.fixed}
                            alt={author.name}
                            />
                        <div id={style.details}>
                            <strong>{author.name}</strong>
                            <p>{author.bio}</p>
                            {(author.bioLinkText && author.bioLinkAdr) &&
                                <IndyLink adr={author.bioLinkAdr}>{author.bioLinkText}</IndyLink>
                            }
                        </div>
                    </div>
                    <div id={style.postInfo}>
                        <RevTime revisions={post.frontmatter.revisions} />
                        <p>{post.fields.readingTime.text}</p>
                    </div>
                </div>
                <Separator />
                <Markdown html={post.html} contentsTable={toc} />
                <Separator />
                <div id={style.footer}>
                    <div id={style.footerBTB}>
                        <IndyLink adr="/blog">
                            <h3><i className="fa fa-arrow-left" aria-hidden="true"></i>Read more</h3>
                        </IndyLink>
                    </div>
                    <div id={style.sharing}>
                        <h3>We don't have sharing buttons! <Emoji name='hushed'/></h3>
                        <p>Even without clicking on them, sharing buttons invade your privacy, so we don't use them. If you like this post, <strong>please share it by copying the link in your browser</strong>.</p>
                    </div>
                </div>

            </div>
        </PageWrap>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
query PostBySlug($slug: String) {
    post: markdownRemark(
        fields: {
            slug: {
                eq: $slug
            }, 
            mdType: {
                eq: "post"
            }
        }
    ) {
        html
        tableOfContents(
            absolute: false
        )
        frontmatter {
            title
            previewText
            showContentsTable
            author {
                name
                bio,
                bioLinkText,
                bioLinkAdr,
                profilePic {
                    publicURL
                    childImageSharp {
                        fixed(width: 80, height: 80, fit: COVER, cropFocus: CENTER) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
            featuredImg {
                publicURL
                childImageSharp {
                    fixed(width: 800, height: 400, fit: COVER, cropFocus: CENTER) {
                        src
                    }
                }
            }
            featuredImgAlt
            featuredImgCredit
            revisions {
                time
                title
            }
        }
        fields {
            slug
            readingTime {
                text
            }
        }
        wordCount{
            words
        }
    }
}
`;