import React from 'react';

import style from './style.module.scss';

export function Separator({id, margin}){
    let className = style.separator;
    if (margin == "TOP" || margin == "BOTH"){
        className += " " + style.margTop;
    }

    return <div id={id} className={className} />;
}