import React from 'react';

/**
 * Uses naming convention from https://www.webfx.com/tools/emoji-cheat-sheet/
 */
export default function Emoji({ name, label }) {
    const emoji = require("emoji-dictionary");

    return (
        <span
            className="emoji"
            role="img"
            aria-label={label ? label : ""}
            aria-hidden={label ? "false" : "true"}
        >
            {emoji.getUnicode(name)}
        </span>
    );
}

