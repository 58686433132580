import React from 'react';
import PropTypes from "prop-types";

import moment from 'moment';

class RevTime extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        let lastTS = 0;
        for (let i = 0; i < this.props.revisions.length; i++) {
            const ts = this.props.revisions[i].time;
            if (ts > lastTS){
                lastTS = ts;
            }
        }

        moment().local();
        let DT = moment.unix(lastTS);

        let timeStrStart;
        if (this.props.revisions.length == 1){
            timeStrStart = "Written ";
        }else{
            timeStrStart = "Updated ";
        }

        return (
            <p>{timeStrStart}<time dateTime={DT.toISOString()}>{DT.format("Do MMMM YYYY")}</time></p>
        );
    }
}

RevTime.propTypes = {
    revisions: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number.isRequired
        })
    ).isRequired
};

export default RevTime;